//import { Images } from "../../../../src/Constant/Images";
import React, { useEffect, useState } from "react";
import AOS from "aos";

import axios from "axios";
import env from "react-dotenv";
import Swal from "sweetalert2";

const ContactUs = () => {
  const api_link = env.LOCAL_API_LINK;

  const [contactData, setContactdata] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const HandleChangeContact = (e) => {
    const { name, value } = e.target;
    setContactdata({
      ...contactData,
      [name]: value,
    });
  };

  const contactSubmit = (e) => {
    e.preventDefault();

    axios({
      method: "post",
      url: `${api_link}/Contact/SaveContact`,
      data: contactData,
    }).then((response) => {

      if(response.status == 200){

        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        Toast.fire({
          icon: "success",
          title: "Your request submitted."
        });

        setContactdata({
          name: "",
          email: "",
          subject: "",
          message: "",
        });

      }
    });
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>Contact</h2>
            <p>Contact Us</p>
          </header>

          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-geo-alt"></i>
                    <h3>Address</h3>
                    <p>
                      Office # 103, Tanveer Associates,
                      <br /> F-18 Islamabad, Pakistan
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p>+92 318 097 4104</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>
                      info@zamstack.com
                      <br />
                      contact@zamstack.com
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-clock"></i>
                    <h3>Open Hours</h3>
                    <p>
                      Monday - Friday
                      <br />
                      11:00 AM - 08:00 PM
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <form
                onSubmit={(e) => contactSubmit(e)}
                className="php-email-form"
              >
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      value={contactData.name}
                      onChange={(e) => HandleChangeContact(e)}
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>

                  <div className="col-md-6 ">
                    <input
                      type="email"
                      value={contactData.email}
                      onChange={(e) => HandleChangeContact(e)}
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <input
                      type="text"
                      value={contactData.subject}
                      onChange={(e) => HandleChangeContact(e)}
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <textarea
                      value={contactData.message}
                      onChange={(e) => HandleChangeContact(e)}
                      className="form-control"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>

                  <div className="col-md-12 text-center">
                    {/* <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div> */}

                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
