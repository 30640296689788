import React, { useEffect, useState } from "react";
import Header from "./Components/Header/Header";
//import HeroSection from "./Components/HeroSection/HeroSection";
import MainSection from "./Components/MainSection/MainSection";
import Footer from "./Components/Footer/Footer";
import "../src/assets/css/style.css";
import { Routes, Route } from "react-router";
import Blog1 from "./pages/Blog1";
import Blog2 from "./pages/Blog2";
import Blog3 from "./pages/Blog3";
import Blog4 from "./pages/Blog4";
import PortfolioPage from "./pages/PortfolioPages/Portfolio";
//import CookieBanner from "./Components/Container/CookiesBanner/CookiesBanner";
//import Subscribe from './Components/Container/SubscribeModal/SubscribeModal'
import Privacy from "./pages/Privacy Policy/Privacy";

const App = () => {
  //const [showSubscribe, setShowSubscribe] = useState(false);
  // const [showCookiesBanner, setShowCookiesBanner] = useState(false);

  // useEffect(() => {
  //   //   const timer = setTimeout(() => {
  //   //     setShowSubscribe(true);
  //   //   }, 10000);

  //   const timer2 = setTimeout(() => {
  //     setShowCookiesBanner(true);
  //   }, 3000);

  //   return () => {
  //     clearTimeout(timer2);
  //   };
  // }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<MainSection />} />
        <Route path="/blog1" element={<Blog1 />} />
        <Route path="/blog2" element={<Blog2 />} />
        <Route path="/blog3" element={<Blog3 />} />
        <Route path="/blog4" element={<Blog4 />} />
        <Route path="/PortfolioPage" element={<PortfolioPage />} />
        <Route path="/Privacy" element={<Privacy />} />
      </Routes>
      <Header />
      {/* <HeroSection /> */}
      {/* {showSubscribe && <Subscribe />} */}
      {/* {showCookiesBanner && <CookieBanner />} */}
      <Footer />
    </>
  );
};

export default App;
