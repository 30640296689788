import React, { useEffect } from "react";
//import { Images } from "../../../Constant/Images";

import AOS from "aos";
//import { Link, useNavigate } from "react-router-dom";
const TechnologiesWeUse = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section id="TechnologiesWeUse" className="counts">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>Technologies We Use</h2>
            <p>Powering Innovation with Advanced Tech</p>
          </header>

          <div className="row gy-4">
            <div className="col-lg-4 col-md-6">
              <div
                className="count-box"
                style={{ minHeight: "100%", placeItems: "flex-start" }}
              >
                <div>
                  <span className="purecounter">
                    <i className="bi bi-view-list"></i> Front-End
                  </span>
                  <ul>
                    <li className="mt-2">HTML, CSS & JS</li>
                    <li className="mt-2">Angular</li>
                    <li className="mt-2">React</li>
                    <li className="mt-2">Next JS</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="count-box"
                style={{ minHeight: "100%", placeItems: "flex-start" }}
              >
                <div>
                  <span className="purecounter">
                    <i
                      className="bi bi-pc-display"
                      style={{ color: "#ee6c20" }}
                    ></i>{" "}
                    Back-End
                  </span>
                  <ul>
                    <li className="mt-2">.NET 6,7,8</li>
                    <li className="mt-2">ASP.NET MVC</li>
                    <li className="mt-2">C#</li>
                    <li className="mt-2">Razor Pages</li>
                    <li className="mt-2">Node Js</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="count-box"
                style={{ minHeight: "100%", placeItems: "flex-start" }}
              >
                <div>
                  <span className="purecounter">
                    <i className="bi bi-cloud" style={{ color: "#15be56" }}></i>{" "}
                    Cloud
                  </span>
                  <ul>
                    <li className="mt-2">AWS</li>
                    <li className="mt-2">AZURE</li>
                    <li className="mt-2">Google Cloud</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row gy-4 mt-3">
            <div className="col-lg-4 col-md-6">
              <div
                className="count-box"
                style={{ minHeight: "100%", placeItems: "flex-start" }}
              >
                <div>
                  <span className="purecounter">
                    <i className="bi bi-pc" style={{ color: "#15be56" }}></i>{" "}
                    Platform
                  </span>
                  <ul>
                    <li className="mt-2">MERN</li>
                    <li className="mt-2">MEAN</li>
                    <li className="mt-2">WordPress</li>
                    <li className="mt-2">.NET</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="count-box"
                style={{ minHeight: "100%", placeItems: "flex-start" }}
              >
                <div>
                  <span className="purecounter">
                    <i className="bi bi-view-list"></i>Mobile D..
                  </span>
                  <ul>
                    <li className="mt-2">Flutter</li>
                    <li className="mt-2">React Native</li>
                    <li className="mt-2">Ionic</li>
                    <li className="mt-2">Android</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="count-box"
                style={{ minHeight: "100%", placeItems: "flex-start" }}
              >
                <div>
                  <span className="purecounter">
                    <i
                      className="bi bi-pc-display"
                      style={{ color: "#ee6c20" }}
                    ></i>
                    Database
                  </span>
                  <ul>
                    <li className="mt-2">MS SQL Server</li>
                    <li className="mt-2">MY SQL</li>
                    <li className="mt-2">Mongo DB</li>
                    <li className="mt-2">Firebase</li>
                    <li className="mt-2">Oracle</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnologiesWeUse;
