import React, { useEffect } from "react";
import AOS from "aos";
//import { Images } from "../../../../src/Constant/Images";

//import { Links } from "react-router-dom";
const Services = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>Services</h2>
            <p>Transforming Ideas into Powerful Software Solutions</p>
          </header>
          <h4 className="text-center">
            we offer flexible & cost-effective servies that empowering your
            digital journey.
            <br /> Our Comprehensive Suite of Services Includes
          </h4>
          <div className="row gy-4">
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="service-box blue">
                <i className="ri-pages-line icon"></i>
                <h3>FullStack Web Development</h3>
                <p>
                  Craft end-to-end web solutions tailored to your business
                  needs. From frontend design to backend development, we deliver
                  seamless and scalable applications. Harness the power of
                  fullstack development for enhanced user experiences and
                  business growth.
                </p>
                {/* <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="service-box orange">
                <i className="ri-cloud-line icon"></i>
                <h3>Cloud Application Development & Integration</h3>
                <p>
                  Transform your business with scalable cloud solutions tailored
                  to your needs. Seamlessly integrate cloud services to enhance
                  productivity and efficiency. Leverage the power of the cloud
                  for agility and growth.
                </p>
                {/* <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="service-box green">
                <i className="ri-smartphone-line icon"></i>
                <h3>Mobile App Development</h3>
                <p>
                  Create engaging mobile experiences that captivate users and
                  drive business growth. From concept to launch, we specialize
                  in crafting high-performance, user-friendly mobile
                  applications. Stay ahead in the mobile-first world with our
                  innovative solutions.
                </p>
                {/* <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="service-box red">
                <i className="ri-window-line icon"></i>
                <h3>Custom Software Development</h3>
                <p>
                  Unlock the full potential of your business with custom
                  software solutions. Our team of experts designs and develops
                  tailored software to address your unique challenges and goals.
                  Drive efficiency, innovation, and competitiveness with our
                  bespoke software development services.
                </p>
                {/* <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="service-box purple">
                <i className="ri-computer-line icon"></i>
                <h3>Desktop Applications</h3>
                <p>
                  Empower your workforce and customers with powerful desktop
                  applications. Our solutions are designed for performance,
                  reliability, and user satisfaction. From standalone tools to
                  enterprise-grade software, we deliver desktop applications
                  that meet your requirements and exceed expectations.
                </p>
                {/* <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <div className="service-box pink">
                <i className="ri-device-line icon"></i>
                <h3>DevOps + Deployment & Automation</h3>
                <p>
                  Accelerate your development cycle and streamline deployment
                  with DevOps practices. Our team implements automation and
                  continuous integration/continuous deployment (CI/CD) pipelines
                  for faster delivery and improved reliability. Maximize
                  efficiency and minimize downtime with our DevOps solutions.
                </p>
                {/* <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <div className="service-box orange">
                <i className="bi bi-brush icon py-4"></i>
                <h3>Graphic Designing</h3>
                <p>
                  Make a lasting impression with stunning visual designs that
                  reflect your brand identity. Our creative team crafts
                  captivating graphics for digital and print media, ensuring a
                  consistent and memorable brand experience. From logos to
                  marketing collateral, we elevate your brand through compelling
                  design.
                </p>
                {/* <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <div className="service-box blue">
                <i className="bi bi-megaphone icon py-4"></i>
                <h3>Digital Marketing</h3>
                <p>
                  Stand out in the digital landscape and reach your target
                  audience effectively. Our comprehensive digital marketing
                  services cover everything from social media management to
                  content creation and email marketing. Drive engagement,
                  generate leads, and achieve measurable results with our
                  strategic approach.
                </p>
                {/* <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <div className="service-box red">
                <i className="ri-seo-line icon"></i>
                <h3>SEO & Website Optimization</h3>
                <p>
                  Boost your online visibility and drive organic traffic to your
                  website with our SEO services. We optimize your website's
                  structure, content, and performance to rank higher in search
                  engine results. Stay ahead of the competition and attract more
                  visitors with our proven SEO strategies.
                </p>
                {/* <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
