
import React, { useEffect } from 'react'
//import { Images } from '../../Constant/Images'

import AOS from "aos"
//import { Link, useNavigate } from 'react-router-dom';
//import BlogData from '../../Constant/Data/BlogData';
import HeroSection from '../HeroSection/HeroSection';
import OurValues from '../Container/Our Values/OurValues';
//import HappyClients from '../Container/HappyClinets/HappyClients';
import Services from '../Container/Services/Services';
//import Pricings from '../Container/Pricings/Pricings';
//import Portfolio from '../Container/Portfolio/Portfolio';
//import Testimonails from '../Container/Testimonials/Testimonails';
//import Team from '../Container/Team/Team';
//import OurClinets from '../Container/OurClients/OurClinets';
//import Blogs from '../Container/Blogs/Blogs';
import ContactUs from '../Container/ContactUs/ContactUs';
import Faqs from '../Container/FAQS/Faqs';
import Features from '../Container/Features/Features';
import TechnologiesWeUse from '../Container/TechnologiesWeUse/TechnologiesWeUse'
import WhoWeAre from '../Container/WhoWeAre/WhoWeAre'

const MainSection = () => {
    useEffect(() => {
        AOS.init()
    }, [])
    return (
        <>
            <HeroSection />
            <main id="main">
                <WhoWeAre />
                <OurValues />
                <Services />
                <TechnologiesWeUse /> 
                {/* <HappyClients /> */}
                <Features />
                {/* <Pricings /> */}
                <Faqs />
                {/* <Portfolio /> */}
                {/* <Testimonails /> */}
                {/* <Team /> */}
                {/* <OurClinets /> */}
                {/* <Blogs BlogData={BlogData} /> */}
                <ContactUs />
            </main>
        </>
    )
}

export default MainSection