import React, { useEffect } from 'react'
import { Images } from '../../../../src/Constant/Images'

import AOS from "aos"

const OurValues = () => {
    useEffect(() => {
        AOS.init()
    }, [])
    return (
        <>
            <section id="values" className="values">

                <div className="container" data-aos="fade-up">

                    <header className="section-header">
                        <h2>Our Values</h2>
                        <p>Our Commitment to Excellence and Innovation</p>
                    </header>

                    <div className="row">

                        <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="box">
                                <img src={Images.Value1} className="img-fluid" alt="" />
                                <h3>Customer Success at Our Core</h3>
                                <p> Building Tomorrow's Solutions Today. At Zam Stack, we believe that our success lies in the success of our clients. That's why we're dedicated to building cutting-edge software solutions today that anticipate and meet the needs of tomorrow, empowering you to thrive in an ever-evolving digital landscape.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
                            <div className="box">
                                <img src={Images.Value2} className="img-fluid" alt="" />
                                <h3>Coding with Purpose, Crafting with Care</h3>
                                <p>Your Trusted Tech Partner. At Zam Stack, we approach every project with a clear sense of purpose and a commitment to excellence. Our team of expert engineers and designers meticulously craft each solution with care, ensuring that every line of code contributes to your success and satisfaction.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
                            <div className="box">
                                <img src={Images.Value3} className="img-fluid" alt="" />
                                <h3>Crafting Software, Building Futures</h3>
                                <p>Unleashing Your Potential. At Zam Stack, we're more than just software developers—we're builders of futures. By leveraging our expertise and passion for technology, we help unlock your full potential, enabling you to innovate, grow, and succeed in an increasingly digital world.</p>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
        </>
    )
}

export default OurValues