import React, { useEffect, useState } from 'react'
import { Images } from '../../../../src/Constant/Images'

import AOS from "aos"
const Features = () => {
    useEffect(() => {
        AOS.init()
    }, [])
    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
    return (
        <>
            <section id="features" className="features">

                <div className="container" data-aos="fade-up">

                    <header className="section-header">
                        <h2>Our Areas Of Expertise</h2>
                        <p>Industries We Served</p>
                    </header>
                    <h3 className='text-center'>Industories we provide services but limited to</h3>
                    <div className="row">

                        <div className="col-lg-6">
                            <img src={Images.Features} className="img-fluid" alt="Zam Stack Technologies" />
                        </div>

                        <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                            <div className="row align-self-center gy-4">

                                <div className="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                                    <div className="feature-box d-flex align-items-center">
                                        <i className="bi bi-check"></i>
                                        <h3>Healthcare</h3>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                                    <div className="feature-box d-flex align-items-center">
                                        <i className="bi bi-check"></i>
                                        <h3>Retail and E-commerce</h3>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                                    <div className="feature-box d-flex align-items-center">
                                        <i className="bi bi-check"></i>
                                        <h3>Education</h3>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                                    <div className="feature-box d-flex align-items-center">
                                        <i className="bi bi-check"></i>
                                        <h3>Manufacturing</h3>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                                    <div className="feature-box d-flex align-items-center">
                                        <i className="bi bi-check"></i>
                                        <h3>Transportation and Logistics</h3>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                                    <div className="feature-box d-flex align-items-center">
                                        <i className="bi bi-check"></i>
                                        <h3>Finance and Banking</h3>
                                    </div>
                                </div>
                                <div className="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                                    <div className="feature-box d-flex align-items-center">
                                        <i className="bi bi-check"></i>
                                        <h3>Business /IT Services</h3>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                                    <div className="feature-box d-flex align-items-center">
                                        <i className="bi bi-check"></i>
                                        <h3>Construction</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    {/* <div className="row feture-tabs" data-aos="fade-up">
                        <div className="col-lg-6">
                            <h3>Neque officiis dolore maiores et exercitationem quae est seda lidera pat claero</h3>

                            <ul className="nav nav-pills mb-3">
                                <li>
                                    <a
                                        style={{ cursor: "pointer" }}
                                        className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('tab1')}
                                    >
                                        Saepe fuga
                                    </a>
                                </li>
                                <li>
                                    <a
                                        style={{ cursor: "pointer" }}
                                        className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('tab2')}
                                    >
                                        Voluptates
                                    </a>
                                </li>
                                <li>
                                    <a
                                        style={{ cursor: "pointer" }}
                                        className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('tab3')}
                                    >
                                        Corrupti
                                    </a>
                                </li>
                            </ul>

                            <div className="tab-content">
                                <div className={`tab-pane fade show ${activeTab === 'tab1' ? 'active' : ''}`} id="tab1">
                                    <div className="tab-pane fade show active" id="tab1">
                                        <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                                        <div className="d-flex align-items-center mb-2">
                                            <i className="bi bi-check2"></i>
                                            <h4>Repudiandae rerum velit modi et officia quasi facilis</h4>
                                        </div>
                                        <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                                        <div className="d-flex align-items-center mb-2">
                                            <i className="bi bi-check2"></i>
                                            <h4>Incidunt non veritatis illum ea ut nisi</h4>
                                        </div>
                                        <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                                    </div>
                                </div>

                                <div className={`tab-pane fade show ${activeTab === 'tab2' ? 'active' : ''}`} id="tab2">
                                    <div className="tab-pane fade show" id="tab2">
                                        <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                                        <div className="d-flex align-items-center mb-2">
                                            <i className="bi bi-check2"></i>
                                            <h4>Hello Change 2 rerum velit modi et officia quasi facilis</h4>
                                        </div>
                                        <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                                        <div className="d-flex align-items-center mb-2">
                                            <i className="bi bi-check2"></i>
                                            <h4>Incidunt non veritatis illum ea ut nisi</h4>
                                        </div>
                                        <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                                    </div>
                                </div>

                                <div className={`tab-pane fade show ${activeTab === 'tab3' ? 'active' : ''}`} id="tab3">

                                    <div className="tab-pane fade show" id="tab3">
                                        <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                                        <div className="d-flex align-items-center mb-2">
                                            <i className="bi bi-check2"></i>
                                            <h4>Hello Change 3 velit modi et officia quasi facilis</h4>
                                        </div>
                                        <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                                        <div className="d-flex align-items-center mb-2">
                                            <i className="bi bi-check2"></i>
                                            <h4>Incidunt non veritatis illum ea ut nisi</h4>
                                        </div>
                                        <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <img src={Images.Features2} className="img-fluid" alt="" />
                        </div>

                    </div> */}

                    <div className="row feature-icons" data-aos="fade-up">

                    <header className="section-header">
                        <h2>Features</h2>
                        <p>Discover the Power of Our Software Solutions</p>
                    </header>
                        <h3>Key Features to Propel Your Business Forward</h3>

                        <div className="row">

                            <div className="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
                                <img src={Images.Features3} className="img-fluid p-4" alt="Zam Stack Technologies" />
                            </div>

                            <div className="col-xl-8 d-flex content">
                                <div className="row align-self-center gy-4">

                                    <div className="col-md-6 icon-box" data-aos="fade-up">
                                        <i className="ri-line-chart-line"></i>
                                        <div>
                                            <h4>Scalability</h4>
                                            <p>Solutions that can grow and adapt with your clients' businesses as they evolve.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                                        <i className="ri-stack-line"></i>
                                        <div>
                                            <h4>User-friendly Interface</h4>
                                            <p>Intuitive and easy-to-use interfaces that enhance user experience and productivity.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                        <i className="ri-brush-4-line"></i>
                                        <div>
                                            <h4>Cross-platform Compatibility</h4>
                                            <p>Software that works seamlessly across various devices and platforms, ensuring accessibility for all users.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                                        <i className="ri-magic-line"></i>
                                        <div>
                                            <h4>Security</h4>
                                            <p>Robust security measures to protect sensitive data and ensure compliance with industry standards.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                                        <i className="ri-command-line"></i>
                                        <div>
                                            <h4>Integration Capabilities</h4>
                                            <p>Ability to integrate with existing systems and third-party applications for enhanced functionality.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                                        <i className="ri-radar-line"></i>
                                        <div>
                                            <h4>Cloud-based Solutions</h4>
                                            <p>Scalable and flexible cloud solutions that offer accessibility and reliability.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>
        </>
    )
}

export default Features