import React, { useState } from "react";
import axios from "axios";
import env from "react-dotenv";
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';


const Footer = () => {
  const api_link = env.LOCAL_API_LINK;

  const [NewsLetterText, setNewsLetterText] = useState({
    email: ""
  });

  const HandleChangeNewsLetter = (e) => {
    const { name, value } = e.target;
    setNewsLetterText({
      ...NewsLetterText,
      [name]: value,
    });
  };

  const newsLetterSubmit = (e) => {
    e.preventDefault();

    axios({
      method: "post",
      url: `${api_link}/NewsLetter/AddNewsLetter`,
      data: NewsLetterText,
    }).then((response) => {
      
      if (response.status == 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });

        Toast.fire({
          icon: "success",
          title: "Thank you for joining our NewsLetter.",
        });

        setNewsLetterText({
            email: "" 
          });
      }
    });
  };

  return (
    <>
      <footer id="footer" className="footer">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <h4>Our Newsletter</h4>
                <p>
                  Stay connected and never miss out on important announcements
                  or special promotions.
                  <br /> subscribe to our newsletter today.
                </p>
              </div>
              <div className="col-lg-6">
                <form onSubmit={(e) => newsLetterSubmit(e)}>
                  <input
                    onChange={(e) => HandleChangeNewsLetter(e)}
                    style={{ outline: "none" }}
                    autoComplete="off"
                    type="email"
                    required
                    name="email"
                    value={NewsLetterText.email}
                    placeholder="Enter your email to subscribe"
                  />
                  <input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-top">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-5 col-md-12 footer-info">
                <a href="/" className="logo d-flex align-items-center">
                  <img
                    src="assets/AppAssets/LogoTransparent.png"
                    alt="Zam Stack Technologies"
                  />
                  <span>Zam Stack</span>
                  <span
                    style={{
                      fontSize: "16px",
                      position: "absolute",
                      marginTop: "52px",
                      marginLeft: "57px",
                      fontWeight: "600",
                      marginTop: "50px",
                    }}
                  >
                    Technologies
                  </span>
                </a>
                <p className="mt-5">
                  We build software solutions. You focus on your business while
                  we take care of all your software development and IT needs.
                </p>
                <div className="social-links mt-3">
                  <a
                    href="https://twitter.com/zamstack"
                    target="_blank"
                    className="twitter"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/zamstack"
                    target="_blank"
                    className="facebook"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@zamstackTech"
                    target="_blank"
                    className="facebook"
                  >
                    <i className="bi bi-youtube"></i>
                  </a>
                  <a
                    href="https://www.tiktok.com/@zamstack"
                    target="_blank"
                    className="instagram"
                  >
                    <i className="bi bi-tiktok"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/zamstack/"
                    target="_blank"
                    className="instagram"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a
                    href="https://www.threads.net/@zamstack"
                    target="_blank"
                    className="facebook"
                  >
                    <i className="bi bi-threads"></i>
                  </a>
                  <a
                    href="https://join.skype.com/invite/xAhh387c5vKY"
                    target="_blank"
                    className="facebook"
                  >
                    <i className="bi bi-skype"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/zam-stack-technologies"
                    target="_blank"
                    className="linkedin"
                  >
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>

              <div className="col-lg-2 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right"></i> <a href="/">Home</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/#about">About us</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/#services">Services</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/">Terms of service</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <Link to="/Privacy">Privacy policy</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/#TechnologiesWeUse">Web Design</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/#TechnologiesWeUse">FullStack Web Development</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/#TechnologiesWeUse">Mobile App Development</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/#TechnologiesWeUse">Custom Software Development</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/#TechnologiesWeUse">Graphic Design</a>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"></i>{" "}
                    <a href="/#TechnologiesWeUse">SEO & Website Optimization</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>
                  Office # 103, Tanveer Associates, F-18 Islamabad, Pakistan{" "}
                  <br />
                  <br />
                  <strong>Phone:</strong> +92 318 097 4104
                  <br />
                  <strong>Email:</strong> info@zamstack.com
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright 2024{" "}
            <strong>
              <span>Zam Stack Technologies</span>
            </strong>
            . All Rights Reserved.
          </div>
          {/* <div className="credits">
                        Designed by <a href="https://zamStack.com/">ZamStack</a>
                    </div> */}
        </div>
      </footer>

    </>
  )
};
export default Footer;
