import React from 'react'
import { Images } from '../../Constant/Images'
const HeroSection = () => {
    return (
        <>
            <section id="hero" className="hero d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <h1 data-aos="fade-up">We offer modern solutions for growing your business</h1>
                            <h2 data-aos="fade-up" data-aos-delay="400">We build software solutions. You focus on your business while we take care of all your software development and IT needs.</h2>
                            <div data-aos="fade-up" data-aos-delay="600">
                                <div className="text-center text-lg-start">
                                    <a href="https://calendly.com/zamstack/booking-session" target='_blank' className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                        <span>Book Now</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                            <img src={Images.Hero} className="img-fluid" alt="Zam Stack Technologies" />
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default HeroSection