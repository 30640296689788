//import Aos from 'aos'
import React, { useEffect } from "react";
//import { Images } from '../../../Constant/Images'

import AOS from "aos";
//import { Link, useNavigate } from 'react-router-dom';
const WhoWeAre = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>About Us</h2>
            <p>
              Welcome to Zam Stack, where innovation meets excellence in
              software solutions
            </p>
          </header>

          <div className="row gx-0">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="content">
                <h3>Who We Are</h3>
                <h2>
                  Empowering Innovation Through Cutting-Edge Technology
                  Solutions
                </h2>
                <p>
                  At Zam Stack Technologies, we are a passionate team of
                  forward-thinking software engineers, designers, and
                  strategists dedicated to revolutionizing industries through
                  our innovative software solutions. With a relentless focus on
                  excellence and a commitment to staying at the forefront of
                  technological advancements, we empower businesses to thrive in
                  the digital era. Our tailored approach, coupled with deep
                  industry expertise, enables us to deliver cutting-edge
                  software products that drive growth, streamline processes, and
                  exceed client expectations. From concept to execution, we
                  leverage our creativity, agility, and technical prowess to
                  transform ideas into scalable, impactful solutions that shape
                  the future of technology.
                </p>
                <div className="text-center text-lg-start">
                  <a
                    href="/#about"
                    className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              {/* <img src={Images.About} className="img-fluid" alt="Zam Stack Technologies" /> */}
              <img
                src="assets/whoweare-removebg.png"
                className="img-fluid"
                alt="Zam Stack Technologies"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoWeAre;
