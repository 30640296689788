import React, { useEffect, useState } from "react";
import AOS from "aos";

const Faqs = () => {
  const [activeItems, setActiveItems] = useState({
    faqlist1: null,
    faqlist2: null,
  });

  const handleToggle = (faqListId, index) => {
    setActiveItems((prevState) => ({
      ...prevState,
      [faqListId]: prevState[faqListId] === index ? null : index,
    }));
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const renderAccordionItem = (item, index, faqListId) => (
    <div className="accordion-item" key={`faq-${faqListId}-item-${index}`}>
      <h2 className="accordion-header">
        <button
          style={{ display: "flex", alignItems: "flex-start" }}
          className={`accordion-button ${
            activeItems[faqListId] === index ? "" : "collapsed"
          }`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#faq-${faqListId}-content-${index}`}
          onClick={() => handleToggle(faqListId, index)}
        >
          {item.question}
        </button>
      </h2>
      <div
        id={`faq-${faqListId}-content-${index}`}
        className={`accordion-collapse collapse ${
          activeItems[faqListId] === index ? "show" : ""
        }`}
        data-bs-parent={`#${faqListId}`}
      >
        <div className="accordion-body">{item.answer}</div>
      </div>
    </div>
  );

  const faqList1 = [
    {
      question: "What services does your software company offer?",
      answer:
        "Our software company specializes in custom software development, including web applications, mobile apps, and enterprise solutions. We also offer services such as software integration, maintenance, and support.",
    },
    {
      question: "How experienced is your team in software development?",
      answer:
        "Our team comprises highly skilled software engineers, designers, and developers with extensive experience in various programming languages, frameworks, and technologies. We have successfully completed numerous projects across diverse industries.",
    },
    {
      question: "Can you provide examples of projects you've worked on?",
      answer:
        "Certainly! You can explore our portfolio on our website, where we showcase some of our past projects along with client testimonials.",
    },
    {
      question: "Can your software integrate with existing systems we use?",
      answer:
        "Yes, our software solutions are designed to be highly compatible and can integrate seamlessly with existing systems and third-party applications to ensure smooth workflow and data exchange.",
    },
    {
      question:
        "What are the costs associated with custom software development?",
      answer:
        "The cost of custom software development varies depending on factors such as project complexity, features, and timeline. We offer transparent pricing and work closely with our clients to provide accurate cost estimates upfront.",
    },
  ];

  const faqList2 = [
    {
      question: "How do you ensure the security of the software you develop?",
      answer:
        "Security is a top priority for us. We follow industry best practices and employ robust security measures such as encryption, authentication, and regular security audits to safeguard our software and protect sensitive data.",
    },
    {
      question:
        "What is your process for software development and project management?",
      answer:
        "We follow an agile development methodology, which allows for flexibility, collaboration, and iterative improvements throughout the project lifecycle. Our project management approach ensures clear communication, timely delivery, and client involvement at every stage.",
    },
    {
      question: "How long does it typically take to develop custom software?",
      answer:
        "The development timeline depends on various factors such as project scope, complexity, and client requirements. We work closely with our clients to establish realistic timelines and milestones to ensure timely delivery.",
    },
    {
      question:
        "What kind of support and maintenance do you offer after the software is deployed?",
      answer:
        "We provide comprehensive support and maintenance services to ensure the continued performance and reliability of our software. This includes bug fixes, updates, upgrades, and ongoing technical support as needed.",
    },
    {
      question: "How do you handle software updates and upgrades?",
      answer:
        "We regularly release updates and upgrades to our software to incorporate new features, enhancements, and security patches. Our clients are notified in advance of any updates, and we provide seamless upgrade paths to minimize disruption.",
    },
  ];

  return (
    <>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>F.A.Q</h2>
            <p>Frequently Asked Questions</p>
          </header>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="accordion accordion-flush ps-2 pe-2"
                  id="faqlist1"
                >
                  {faqList1.map((item, index) =>
                    renderAccordionItem(item, index, "faqlist1")
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="accordion accordion-flush ps-2 pe-2"
                  id="faqlist2"
                >
                  {faqList2.map((item, index) =>
                    renderAccordionItem(item, index, "faqlist2")
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
